import api from "./api";

export const getSoaTransactions = ({name = '', page = 0, size = 10, sort = ''}) => {
    const params = new URLSearchParams({
        name: name ?? '',
        page: page ?? 0,
        size: size ?? 10,
        sort: sort ?? ''
    });
    return api.get("/api/soa?" + params);
};

export const getSoaTransaction = ({id}) => {
    return api.get("/api/soa/" + id);
}

export const saveSoaTransaction = ({soa}) => {
    return api.post("/api/soa", soa);
};
