import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Title from '../../../components/Title';
import LoadingSpinner from '../../../components/LoadingSpinner';
import MessageDialog from '../../../components/MessageDialog';
import { addBenefit } from '../../../services/employee-benefits';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import EmployeeNameLookup from '../../../components/EmployeeNameLookup';
import TextField from '@mui/material/TextField';

const emptyForm = {
    type: '',
    employee: null,
    effectiveDate: null,
    effectiveDateMoment: null,
    amount: '',
}

export default function AddBenefit({ benefitTypes }) {
    const navigate = useNavigate();
    const [benefitForm, setBenefitForm] = useState(emptyForm);
    const [loading, setLoading] = useState(false);
    const [popupMessage, setPopupMessage] = useState(null);

    function updateField(fieldName, value) {
        setBenefitForm(b => ({ ...b, [fieldName]: value }));
    }

    function save() {
        setLoading(true)
        const payload = {
            ...benefitForm,
            employeeId: benefitForm.employee?.id,
        };
        addBenefit(payload)
            .then(() => showMessage(true, 'Successfully saved employee benefit.'))
            .catch(err => showMessage(false, err.response?.data))
            .finally(() => setLoading(false));
    }

    function showMessage(success, message) {
        setPopupMessage({
            success,
            title: success ? 'Success' : 'Error',
            message: message?.toString(),
        });
    }

    function onClosePopup() {
        if (popupMessage?.success) {
            setBenefitForm(emptyForm);
        }
        setPopupMessage(null);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Title>Add Benefit</Title>

            <Box sx={{ mt: 5 }} style={{ paddingBottom: 20 }} component="form" noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel required shrink={true}>Transaction Type</InputLabel>
                            <Select value={benefitForm.type}
                                    onChange={(e) => updateField("type", e.target.value)}>
                                {benefitTypes?.map(({ code, description }) => <MenuItem key={code}
                                                                                        value={code}>{description}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <DatePicker label="Effective Date"
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                            InputLabelProps: { shrink: true },
                                            onChange: (newValue) => {
                                                updateField('effectiveDateMoment', newValue);
                                                updateField('effectiveDate', moment(newValue).format('MM/DD/YYYY'));
                                            },
                                        },
                                    }}
                                    onChange={(newValue) => {
                                        updateField('effectiveDateMoment', newValue);
                                        updateField('effectiveDate', moment(newValue).format('MM/DD/YYYY'));
                                    }}
                                    value={benefitForm.effectiveDateMoment}
                                    format="MM/DD/YYYY"
                                    fullWidth/>
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <EmployeeNameLookup
                            value={benefitForm.employee}
                            onChange={value => updateField('employee', value)}
                            showRegularEmployeeIndicator={true}
                        />
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <TextField required
                                   InputLabelProps={{ shrink: true }}
                                   style={{ maxWidth: '200px' }}
                                   label="Amount"
                                   type="number"
                                   value={benefitForm.amount}
                                   inputProps={{
                                       min: 0,
                                       maxLength: 13,
                                       step: "0.1",
                                       lang: "en-US",
                                       style: { textAlign: 'right' }
                                   }}
                                   onChange={(e) => updateField("amount", e.target.value)}
                                   fullWidth
                                   variant="standard"/>
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                </Grid>
            </Box>

            <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                    <Button onClick={save} variant="contained" startIcon={<SaveIcon/>}
                            sx={{ ml: 1, minWidth: 140 }}>
                        Save
                    </Button>
                </Box>
            </AppBar>

            <MessageDialog
                title={popupMessage?.title}
                message={popupMessage?.message}
                open={!!popupMessage}
                onClose={onClosePopup}
            />

            <LoadingSpinner open={loading}/>
        </LocalizationProvider>
    );
}
