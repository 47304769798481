import api from "./api";

export const ROLE_TYPES = [
    'ROLE_ADMIN',
    'ROLE_MODERATOR',
    'ROLE_USER'
];

export const getUsers = ({name='', page = 0, size = 10, sort = 'name'}) => {
    const params = new URLSearchParams({
        name: name,
        page: page,
        size: size,
        sort: sort
    });
    return api.get("/api/user/list?" + params);
};

export const getUser = ({id}) => {
    const params = new URLSearchParams({
        id: id
    });
    return api.get("/api/user?" + params);
};

export const signupUser = ({user}) => {
    return api.post("/api/user/signup", user);
};

export const updateUser = ({user}) => {
    return api.post("/api/user/save", user);
};

export const removeUser = ({id}) => {
    return api.delete("/api/user/" + id);
};

/**
 * @param {{username: string, oldPass: string, newPass: string}} payload
 * @return {Promise<axios.AxiosResponse<any>>}
 */
export const changePass = (payload) => {
    return api.post("/api/user/change-pass", payload);
};

/**
 * @param {{username: string, newPass: string}} payload
 * @return {Promise<axios.AxiosResponse<any>>}
 */
export const resetPass = (payload) => {
    return api.post("/api/user/reset-pass", payload);
};
