import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React from 'react';

/**
 * @param {string} title
 * @param {string} message
 * @param {boolean} open
 * @param {(boolean) => void} onClose
 * @param {string} yesButtonColor
 * @return {Element}
 * @constructor
 */
export default function ConfirmDialog({ title, message, open, onClose, yesButtonColor }) {
    return <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">
            {title ?? 'Confirm'}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message?.split(/\n/)?.map(m => <div>{m}</div>)}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose?.(true)} autoFocus variant="contained" color={yesButtonColor}>
                Yes
            </Button>
            <Button onClick={() => onClose?.(false)} variant="outlined">
                No
            </Button>
        </DialogActions>
    </Dialog>;
}
