import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Title from '../../components/Title';
import { getUser, removeUser, ROLE_TYPES, signupUser, updateUser } from "../../services/users";
import { mergeJSON } from '../../helpers/json-utils';
import MessageDialog from '../../components/MessageDialog';
import ChangePasswordModal from '../../components/ChangePasswordModal';
import ConfirmDialog from '../../components/ConfirmDialog';

const emptyData = {
    id: '',
    username: '',
    password: '',
    lastName: '',
    firstName: '',
    email: '',
    roles: [],
};

export default function User() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [originalData, setOriginalData] = useState(emptyData);
    const [user, setUser] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showChangePass, setShowChangePass] = useState(false);
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);

    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) return;

        setLoading(true);
        getUser({
            id: id
        }).then((res) => {
            const data = mergeJSON(emptyData, res.data);
            setUser(data);
            setOriginalData(data);
            setReadOnly(true);
            setLoading(false);
        });
    }, [id]);

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const save = () => {
        setLoading(true);
        if (!user.id){
            signupUser({
                user: user
            }).then((res) => {
                setLoading(false);
                setUser(res.data);
                setOriginalData(res.data);
                popUpMessage(true, 'Successfully saved user details.');
                setReadOnly(true);
            }).catch((err) => {
                setLoading(false);
                popUpMessage(false, err.response.data);
            });
        } else {
            updateUser({
                user: user
            }).then((res) => {
                setLoading(false);
                setUser(res.data);
                setOriginalData(res.data);
                popUpMessage(true, 'Successfully saved user details.');
                setReadOnly(true);
            }).catch((err) => {
                setLoading(false);
                popUpMessage(false, err.response.data);
            });
        }

    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string' && !['username', 'password'].includes(fieldName)) ? value.toUpperCase(): value;
        setUser(b => ({
            ...b,
            ...dirty
        }));
    };

    const cancel = async () => {
        setUser(originalData);
        setReadOnly(true);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const onRemoveAccount = (confirmed) => {
        setShowConfirmRemove(false);
        if (confirmed) {
            setLoading(true);
            removeUser(user)
                .then(() => {
                    navigate(-1);
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Title>User Details</Title>
            <Box sx={{ mt: 5, minHeight: '60vh' }} component="form">
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly || user.id, disableUnderline: readOnly}}
                            id="userUsername"
                            label="Username"
                            value={user.username}
                            onChange={(e) => updateField('username', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    {!user.id &&
                        <Grid item sm={12}>
                            <TextField required
                                InputLabelProps={{ shrink: true }}
                                InputProps={{readOnly: readOnly, disableUnderline: readOnly,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                id="userPassword"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={user.password}
                                onChange={(e) => updateField('password', e.target.value)}
                                fullWidth
                                variant="standard" />
                        </Grid>
                    }
                    <Grid item sm={12}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="userLastName"
                            label="Last Name"
                            value={user.lastName}
                            onChange={(e) => updateField('lastName', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="userFirstName"
                            label="First Name"
                            value={user.firstName}
                            onChange={(e) => updateField('firstName', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="userEmail"
                            label="Email"
                            value={user.email}
                            onChange={(e) => updateField('email', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={12}>
                        <Autocomplete
                                readOnly={readOnly}
                                multiple
                                options={ROLE_TYPES}
                                value={user.roles}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{...params.InputProps,
                                            disableUnderline: readOnly
                                        }}
                                        variant="standard"
                                        label="Roles" />
                                )}
                                onChange={(e, value) => {
                                    updateField("roles", value);
                                }} />
                    </Grid>
                </Grid>
            </Box>
            
            <MessageDialog
                title={success ? 'Success' : 'Error'}
                message={message}
                open={openMessage}
                onClose={() => setOpenMessage(!openMessage)}
            />
            
            <ConfirmDialog
                title="Remove Account?"
                message="Are you sure you want to remove this account?"
                open={showConfirmRemove}
                onClose={onRemoveAccount}
                yesButtonColor="error"
            />
            
            <ChangePasswordModal 
                username={user.username}
                requireOldPass={false}
                open={showChangePass}
                onClose={() => setShowChangePass(false)}
            />
            
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                    {!readOnly ?
                        <>
                            <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            {!!id &&
                                <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                    Cancel
                                </Button>
                            }
                        </>
                    :
                        <>
                            <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Edit
                            </Button>
                            <Button onClick={() => setShowChangePass(true)} variant="outlined" sx={{ ml: 1 }}>
                                Change Password
                            </Button>
                            <Button onClick={() => setShowConfirmRemove(true)} variant="outlined" sx={{ ml: 1 }} color="error">
                                Remove Account
                            </Button>
                        </>
                    }
                </Box>
            </AppBar>
        </Paper>
    );
}