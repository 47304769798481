import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Title from '../../../components/Title';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../../../helpers/string-utils';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { SoaTransactionTypes } from '../../../constants/enum-types';
import { getSoaTransactions } from '../../../services/statements-of-account';

const employeeNameSortField = 'employee.lastName,employee.firstName,employee.suffix,employee.middleName';

export default function StatementOfAccountList({isForm = false}) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [recordCount, setRecordCount] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const sortBy = searchParams.get('sort') || 'createDate';
        const sortOrder = searchParams.get('sortOrder') || 'desc';

        if (!searchParams.get('sort')) {
            setSearchParams(sp => {
                sp.set('sort', sortBy);
                sp.set('sortOrder', sortOrder);
                return sp;
            });
            return; // this effect will fire again
        }

        getSoaTransactions({
            name: searchParams.get('name') || '',
            page: searchParams.get('currentPage') || 0,
            sort: `${sortBy},${sortOrder}`,
            size: searchParams.get('size') || 25
        }).then((res) => {
            setTransactions(res.data.content);
            setRecordCount(res.data.totalElements);
            setLoading(false);
        });
    }, [searchParams]);

    const sortField = (field) => {
        if (field !== searchParams.get('sort')) {
            updateSearch('sort', field);
            updateSearch('sortOrder', 'asc');
        } else {
            updateSearch('sortOrder', searchParams.get('sortOrder') === "asc" ? "desc" : "asc");
        }
    };

    const handleChangePage = (event, newPage) => {
        updateSearch('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        updateSearch('size', +event.target.value);
        updateSearch('currentPage', 0);
    };

    const updateSearch = (field, value) => {
        setSearchParams(d => {
            d.set(field, value);
            return d;
        })
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Title>Statement of Account (SOA)</Title>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                            <Button startIcon={<AddCircleIcon />} component={Link} to={"/transactions/soa/add-new"}>
                                Add SOA
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField label="Search Name"
                               InputProps={{
                                   startAdornment: <SearchIcon />,
                                   endAdornment: searchParams.get('name') && (
                                       <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => updateSearch('name', '')} />
                                   )
                               }}
                               value={searchParams.get('name') || ''}
                               onChange={(e) => {
                                   updateSearch('currentPage', 0);
                                   updateSearch('name', e.target.value.toUpperCase());
                               }}
                               fullWidth
                               variant="standard" />
                </Grid>
            </Grid>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell width={50}></TableCell>
                            <TableCell width={350} sortDirection={searchParams.get('sort') === employeeNameSortField ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === employeeNameSortField}
                                                direction={searchParams.get('sort') === employeeNameSortField ? searchParams.get('sortOrder') : 'asc'}
                                                onClick={() => sortField(employeeNameSortField)}>
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={150}>Type</TableCell>
                            <TableCell width={170} sortDirection={searchParams.get('sort') === 'createDate' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'createDate'}
                                                direction={searchParams.get('sort') === 'createDate' ? searchParams.get('sortOrder') : 'asc'}
                                                onClick={() => sortField('createDate')}>
                                    Transaction Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell width={170} sortDirection={searchParams.get('sort') === 'effectiveDate' ? searchParams.get('sortOrder') : false }>
                                <TableSortLabel active={searchParams.get('sort') === 'effectiveDate'}
                                                direction={searchParams.get('sort') === 'effectiveDate' ? searchParams.get('sortOrder') : 'asc'}
                                                onClick={() => sortField('effectiveDate')}>
                                    Effective Date
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                    <CircularProgress color="inherit" />
                                </TableCell>
                            </TableRow>
                            :
                            transactions.map(transaction => (
                                    <TableRow hover tabIndex={-1} key={transaction.id}
                                              sx={{ cursor: 'pointer' }}
                                              onClick={() => navigate(`/transactions/soa/${transaction.id}`)}>
                                        <TableCell>
                                            {transaction.hasPhoto ?
                                                <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${transaction.employeeId}?rnd=${new Date().getTime()}`} />
                                                :
                                                <Avatar {...stringAvatar(`${transaction.employeeFullName}`)} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {transaction.employeeFullName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Typography>{SoaTransactionTypes[transaction.type]}</Typography>
                                        </TableCell>
                                        <TableCell style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Typography>{transaction.createDate}</Typography>
                                        </TableCell>
                                        <TableCell style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            borderStyle: "border-box" }}>
                                            <Typography>{transaction.effectiveDate}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination component="div"
                             count={recordCount}
                             rowsPerPage={parseInt(searchParams.get('size') || 25)}
                             page={recordCount > 0 ? parseInt(searchParams.get('currentPage') || 0) : 0}
                             onPageChange={handleChangePage}
                             onRowsPerPageChange={handleChangeRowsPerPage} />
        </React.Fragment>
    );
}
