import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from '@mui/material/Avatar';

import Title from '../../../components/Title';
import { stringAvatar, } from "../../../helpers/string-utils";
import { getBenefitsSummary, getBenefitTypes } from '../../../services/employee-benefits';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { formatCurrency } from '../../../helpers/formatters';

const employeeNameSortField = 'lastName,firstName,suffix,middleName';

export default function EmployeeBenefitList() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [recordCount, setRecordCount] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [benefitTypes, setBenefitTypes] = useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const sortBy = searchParams.get('sort') || employeeNameSortField;
        const sortOrder = searchParams.get('sortOrder') || 'asc';

        getBenefitTypes().then(res => setBenefitTypes(res.data));

        if (!searchParams.get('sort')) {
            setSearchParams(sp => {
                sp.set('sort', sortBy);
                sp.set('sortOrder', sortOrder);
                return sp;
            });
            return; // this effect will fire again
        }

        getBenefitsSummary({
            name: searchParams.get('name') || '',
            page: searchParams.get('currentPage') || 0,
            size: searchParams.get('size') || 25,
            sort: searchParams.get('sort') + ',' + searchParams.get('sortOrder'),
        }).then(res => {
            setLoading(false);
            setRecordCount(res.data.totalElements);
            setEmployees(res.data?.content);
        });
    }, [searchParams]);

    const sortField = (field) => {
        if (field !== searchParams.get('sort')) {
            updateSearch('sort', field);
            updateSearch('sortOrder', 'asc');
        } else {
            updateSearch('sortOrder', searchParams.get('sortOrder') === "asc" ? "desc" : "asc");
        }
    };

    const handleChangePage = (event, newPage) => {
        updateSearch('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        updateSearch('size', +event.target.value);
        updateSearch('currentPage', 0);
    };

    const updateSearch = (field, value) => {
        setSearchParams(d => {
            d.set(field, value);
            return d;
        })
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Title>Benefits Management</Title>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonGroup size="small" variant="contained" aria-label="outlined button group">
                            <Button startIcon={<AddCircleIcon/>} component={Link} to={"/transactions/benefits/add-new"}>
                                Add Benefits
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField label="Search Name"
                               InputProps={{
                                   startAdornment: <SearchIcon/>,
                                   endAdornment: searchParams.get('name') && (
                                       <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => updateSearch('name', '')}/>
                                   )
                               }}
                               value={searchParams.get('name') || ''}
                               onChange={(e) => {
                                   updateSearch('currentPage', 0);
                                   updateSearch('name', e.target.value.toUpperCase());
                               }}
                               fullWidth
                               variant="standard"/>
                </Grid>
            </Grid>
            {benefitTypes?.length && (<>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={50}></TableCell>
                                <TableCell
                                    sortDirection={searchParams.get('sort') === employeeNameSortField ? searchParams.get('sortOrder') : false}>
                                    <TableSortLabel
                                        active={searchParams.get('sort') === employeeNameSortField}
                                        direction={searchParams.get('sort') === employeeNameSortField ? searchParams.get('sortOrder') : 'asc'}
                                        onClick={() => sortField(employeeNameSortField)}>
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                {benefitTypes.map(type =>
                                    <TableCell key={type.code} width={140} align="right">
                                        {type.description}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ?
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                        <CircularProgress color="inherit"/>
                                    </TableCell>
                                </TableRow>
                                :
                                employees.map((employee) => (
                                    <TableRow hover tabIndex={-1} key={employee.employeeId}
                                              sx={{ cursor: 'pointer' }}
                                              onClick={() => navigate(`/transactions/benefits/${employee.employeeId}`)}>
                                        <TableCell>
                                            {employee.hasPhoto ?
                                                <Avatar
                                                    src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${employee.employeeId}?rnd=${new Date().getTime()}`}/>
                                                :
                                                <Avatar {...stringAvatar(`${employee.fullName}`)} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {employee.fullName}
                                        </TableCell>
                                        {benefitTypes.map(type => (
                                            <TableCell key={type.code} align="right">
                                                {formatCurrency(employee.benefits?.find(b => b.type?.code === type.code)?.amount ?? 0)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination component="div"
                                 count={recordCount}
                                 rowsPerPage={parseInt(searchParams.get('size') || 25)}
                                 page={recordCount > 0 ? parseInt(searchParams.get('currentPage') || 0) : 0}
                                 onPageChange={handleChangePage}
                                 onRowsPerPageChange={handleChangeRowsPerPage}/>
            </>)}
        </React.Fragment>
    );
}
