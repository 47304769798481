import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBenefitsSummary, getBenefitTypes } from '../../../services/employee-benefits';
import AddBenefit from './add-benefit';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../../../helpers/string-utils';
import TableContainer from '@mui/material/TableContainer';
import ViewBenefits from './view-benefits';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';

export default function EmployeeBenefit() {
    const { id: viewIdParam } = useParams();
    const [id] = useState(viewIdParam && viewIdParam !== 'add-new' ? viewIdParam : undefined);
    const navigate = useNavigate();
    const [benefitTypes, setBenefitTypes] = useState([]);
    const [benefitSummary, setBenefitSummary] = useState({});
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {
        onInit();
    }, [id]);

    async function onInit() {
        setLoading(true);
        const promises = [
            getBenefitTypes().then(({ data }) => setBenefitTypes(data))
        ];
        if (id) {
            promises.push(getBenefitsSummary({ employeeId: id }).then(({ data }) => setBenefitSummary(data?.content?.[0])));
        }
        await Promise.all(promises);
        setLoading(false);
    }

    return <>
        <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
            <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                <ArrowBackIcon fontSize="inherit"/>
            </IconButton>
        </Stack>

        {viewIdParam === 'add-new'
            ? <AddBenefit benefitTypes={benefitTypes}/>
            : <ViewBenefits benefitSummary={benefitSummary} loading={loading}/>
        }
    </>
}
