import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AppBar from '@mui/material/AppBar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Title from '../../../components/Title';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LoadingSpinner from '../../../components/LoadingSpinner';
import MessageDialog from '../../../components/MessageDialog';
import EmployeeNameLookup from '../../../components/EmployeeNameLookup';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getSoaTransaction, saveSoaTransaction } from '../../../services/statements-of-account';
import { SoaTransactionTypes } from '../../../constants/enum-types';

const emptyTransaction = {
    type: '',
    effectiveDate: null,
    effectiveDateMoment: null,
    employee: null,
}

export default function StatementOfAccount() {
    const { id: viewIdParam } = useParams();
    const [id] = useState(viewIdParam && viewIdParam !== 'add-new' ? viewIdParam : undefined);
    const navigate = useNavigate();
    const [transaction, setTransaction] = useState(emptyTransaction);
    const [readOnly, setReadOnly] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) return;

        setLoading(true);
        getSoaTransaction({ id }).then(({ data }) => {
            setReadOnly(true);
            mapTransactionToView(data);
            setLoading(false);
        });
    }, [id]);

    const mapTransactionToView = (data) => {
        setTransaction({
            ...data,
            employee: {
                id: data.employeeId,
                personalData: {
                    firstName: data.employeeFirstName,
                    lastName: data.employeeLastName,
                    middleName: data.employeeMiddleName,
                },
                employmentInfoData: { noOfMonths: data.noOfMonths },
            },
            effectiveDateMoment: data.effectiveDate ? moment(data.effectiveDate) : null,
        });
    };

    const save = () => {
        setLoading(true);
        const payload = {
            ...transaction,
            employeeId: transaction.employee?.id,
        };
        saveSoaTransaction({ soa: payload })
            .then(({ data }) => {
                setLoading(false);
                setReadOnly(true);
                mapTransactionToView(data)
                showMessage(true, 'Transaction successfully saved.');
            })
            .catch((err) => showMessage(false, err.response?.data))
            .finally(() => setLoading(false));
    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase() : value;
        setTransaction(b => ({
            ...b,
            ...dirty
        }));
    };

    const showMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message?.toString());
        setOpenMessage(true);
    }

    const closeMessage = () => {
        if (success) {
            navigate(`/transactions/soa/${transaction.id}`, { replace: true });
        }
        setOpenMessage(!openMessage);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="inherit"/>
                </IconButton>
            </Stack>
            <Title>Statement of Account (SOA)</Title>
            <Box sx={{ mt: 5 }} style={{ paddingBottom: 20 }} component="form" noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel required shrink={true}>Transaction Type</InputLabel>
                            <Select readOnly={readOnly}
                                    disableUnderline={readOnly}
                                    value={transaction.type}
                                    onChange={(e) => updateField("type", e.target.value)}>
                                {Object.keys(SoaTransactionTypes).sort().map(k =>
                                    <MenuItem key={k} value={k}>{SoaTransactionTypes[k]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <DatePicker label="Effective Date"
                                    readOnly={readOnly}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                            InputLabelProps: { shrink: true },
                                            InputProps: { disableUnderline: readOnly },
                                            onChange: (newValue) => {
                                                updateField('effectiveDateMoment', newValue);
                                                updateField('effectiveDate', moment(newValue).format('MM/DD/YYYY'));
                                            },
                                        },
                                    }}
                                    onChange={(newValue) => {
                                        updateField('effectiveDateMoment', newValue);
                                        updateField('effectiveDate', moment(newValue).format('MM/DD/YYYY'));
                                    }}
                                    value={transaction.effectiveDateMoment}
                                    format="MM/DD/YYYY"
                                    fullWidth/>
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <EmployeeNameLookup
                            readOnly={readOnly}
                            value={transaction.employee}
                            onChange={value => updateField('employee', value)}
                            showRegularEmployeeIndicator={true}
                        />
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                </Grid>
            </Box>

            <MessageDialog
                title={success ? 'Success' : 'Error'}
                message={message}
                open={openMessage}
                onClose={closeMessage}
            />

            <LoadingSpinner open={loading}/>

            {!id &&
                <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                    <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                        {!readOnly
                            ? <Button onClick={save} variant="contained" startIcon={<SaveIcon/>}
                                      sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            : <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon/>}
                                      sx={{ ml: 1, minWidth: 140 }}>
                                Edit
                            </Button>
                        }
                    </Box>
                </AppBar>
            }
        </LocalizationProvider>
    );
}
