import React, { useState } from "react";

import Dashboard from "../../components/dashboard";
import { useParams, useSearchParams } from 'react-router-dom';
import Employees from './Employees';
import Leaves from './Leaves';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AdminOrders from './AdminOrders';
import EmploymentDocuments from './Performance';
import EmployeeBenefits from './Benefits';
import StatementsOfAccount from './StatementOfAccount';

const txnTypes = {
    'employees': Employees,
    'leaves': Leaves,
    'admin-orders': AdminOrders,
    'performance': EmploymentDocuments,
    'benefits': EmployeeBenefits,
    'soa': StatementsOfAccount,
};

export default function Transactions() {
    const { txnType, id } = useParams();
    const [search, setSearch] = useSearchParams();
    const [selectedTxnType, setSelectedTxnType] = useState(txnType || search.get('t') || 'employees');

    const getComponent = () => {
        const Component = txnTypes[selectedTxnType];
        return !!Component ? <Component isForm={!!id}/> : <h1>This page is still under construction.</h1>;
    };

    const onTxnTypeChange = (value) => {
        setSelectedTxnType(value);
        setSearch({t: value});
    };

    return (
        <Dashboard title="Transactions">
            <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
                {!id && <>
                    <FormControl variant="standard" style={{maxWidth: "490px"}}>
                        <InputLabel id="lbl-transaction-type">Transaction:</InputLabel>
                        <Select
                            labelId="lbl-transaction-type"
                            value={selectedTxnType}
                            onChange={e => onTxnTypeChange(e.target.value)}
                        >
                            <MenuItem value={'employees'}>New Employee/Employee Details</MenuItem>
                            <MenuItem value={'leaves'}>Leave Details</MenuItem>
                            <MenuItem value={'admin-orders'}>Admin Orders</MenuItem>
                            <MenuItem value={'performance'}>Performance Management</MenuItem>
                            <MenuItem value={'benefits'}>Benefits Management</MenuItem>
                            <MenuItem value={'payroll'}>Payroll Management</MenuItem>
                            <MenuItem value={'soa'}>SOA</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                </>}

                {getComponent()}
            </Paper>
        </Dashboard>
    );
}
