import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { changePass, resetPass } from '../services/users';
import { Alert } from '@mui/material';
import MessageDialog from './MessageDialog';

const defaultFormValue = {
    oldPass: '',
    newPass: '',
    confirmPass: '',
};

/**
 * @param {string} username
 * @param {string} title
 * @param {boolean} requireOldPass
 * @param {boolean} open
 * @param {() => void} onClose
 * @constructor
 */
export default function ChangePasswordModal({
    username,
    title,
    requireOldPass = true,
    open,
    onClose,
}) {
    const [form, setForm] = useState({ ...defaultFormValue });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        setForm({ ...defaultFormValue });
        setError(null);
    }, [open]);

    const updateField = (field, value) => setForm(d => ({ ...d, [field]: value }));

    const onSave = () => {
        if (form.newPass !== form.confirmPass) {
            setError("New password and confirm password do not match.");
            return;
        }

        setLoading(true);
        const apiCall = requireOldPass ? changePass : resetPass;
        apiCall({ username, ...form })
            .then(() => setMessage("Password was changed successfully."))
            .catch(err => setError(err.response.data))
            .finally(() => setLoading(false));
    };

    const onCloseMessage = () => {
        setMessage(null);
        onClose?.();
    };

    return <>
        <Dialog open={open} xs={{width: '400px'}}>
            <DialogTitle id="alert-dialog-title">
                {title ?? 'Change Password'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3} minWidth={450} maxWidth={450}>
                    {error && <Grid item sm={12}>
                        <Alert variant="filled" severity="error">
                            {error.split('\n').map(e => <div>{e}</div>)}
                        </Alert>
                    </Grid>}

                    {requireOldPass && <Grid item sm={12}>
                        <TextField required
                                   InputLabelProps={{ shrink: true }}
                                   id="oldPass"
                                   label="Old Password"
                                   value={form.oldPass}
                                   onChange={(e) => updateField('oldPass', e.target.value)}
                                   fullWidth
                                   type="password"
                                   variant="standard"/>
                    </Grid>}
                    <Grid item sm={12}>
                        <TextField required
                                   InputLabelProps={{ shrink: true }}
                                   id="newPass"
                                   label="New Password"
                                   value={form.newPass}
                                   onChange={(e) => updateField('newPass', e.target.value)}
                                   fullWidth
                                   type="password"
                                   variant="standard"/>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField required
                                   InputLabelProps={{ shrink: true }}
                                   id="confirmPass"
                                   label="Confirm Password"
                                   value={form.confirmPass}
                                   onChange={(e) => updateField('confirmPass', e.target.value)}
                                   fullWidth
                                   type="password"
                                   variant="standard"/>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant="contained" autoFocus disabled={loading}>
                    Save
                </Button>
                <Button onClick={() => onClose?.()} variant="outlined" disabled={loading}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

        <MessageDialog
            open={!!message}
            message={message}
            title="Success"
            onClose={onCloseMessage}
        />
    </>
}