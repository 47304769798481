import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import Grid from '@mui/material/Grid';
import Title from '../../../components/Title';
import { formatCurrency } from '../../../helpers/formatters';

export default function ViewBenefits({ benefitSummary, loading }) {
    return <>
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <Title>{benefitSummary.fullName}</Title>
            </Grid>
        </Grid>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width={150}>Transaction Date</TableCell>
                        <TableCell width={140}>Effective Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                                <CircularProgress color="inherit"/>
                            </TableCell>
                        </TableRow>
                        :
                        benefitSummary?.benefits?.length
                            ? benefitSummary?.benefits?.map((benefit, index) => (
                                <TableRow key={index}>
                                    <TableCell>{benefit.createDate}</TableCell>
                                    <TableCell>{benefit.effectiveDate}</TableCell>
                                    <TableCell>{benefit.type.description}</TableCell>
                                    <TableCell align="right">{formatCurrency(benefit.amount ?? 0)}</TableCell>
                                </TableRow>
                            ))
                            : <TableRow><TableCell colSpan={4} align="center">No benefits added yet.</TableCell></TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
